class Form {
    constructor($form) {
        this.$form = $form;
        this.submitHijack();
    }
    submitHijack() {
        // Don't run on forms with the novalidate attribute.
        if (typeof this.$form.attr('novalidate') !== typeof undefined && typeof this.$form.attr('novalidate') !== false) { return; }
        // You may be asking why this is bound to the click event of a submit button and not the submit event of the form.
        // Well, dear reader, it turns out that you can't overrule the browser's own validation on the submit event,
        // the browser does the validation before the event is fired, so you can't preventDefault() it without getting rid
        // of the browser validation entirely - which also stops this code from working. 
        // Moral of the story: Maybe don't try and override the browser's standard validation.
        this.$form.find('input[type="submit"], button[type="submit"]').on('click', (event) => {
            event.preventDefault();
            const $inputs = this.$form.find(':input');
            // Remove any existing errors
            let allGood = true;
            this.$form.find('.o-form__help--error');
            this.$form.find('[data-error]').removeAttr('data-error');
            // Loop through inputs
            $inputs.each((i, input) => {
                const $input = $(input);
                const $row = $input.closest('.o-form__row');
                const $control = $input.closest('.o-form__control');
                const $error = $control.siblings('.o-form__help--error').length > 0 ? $control.siblings('.o-form__help--error') : $('<span/>', {
                    'class': 'o-form__help o-form__help--error'
                });
                if(!input.validity.valid) {
                    // This input has an error!
                    allGood = false;
                    $row.attr('data-error', '');
                    if (input.validationMessage) {
                        $control.before($error.text(input.validationMessage));
                    }
                }
            });
            if(allGood) {
                // Submit the form, disable submit button and apply working style
                this.$form.find('[type="submit"].a-button').prop('disabled', true).attr('aria-busy', 'true');
                this.$form.trigger('submit');
            }
            else {
                // Prevent form submission, scroll to the first error
                $('html,body').animate({
                    scrollTop: this.$form.find('[data-error]').eq(0).offset().top
                });
            }
        });
    }
}

$(document).ready(() => {
    'use strict';
    $('.o-form').each((i, form) => {
        new Form($(form));
    });
});