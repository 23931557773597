// General helper functions
class Helper {
    static config() {
        if ($('#configJson').length > 0) {
            return JSON.parse($('#configJson').html());
        }
        return {};
    }
    static randomString() {
        return Math.random().toString(36).substring(6);
    }
    static icon(icon, classes = '') {
        const path = (typeof Helper.config().ICON_PATH !== 'undefined') ? Helper.config().ICON_PATH : '';
        return `<svg class="${classes}" role="presentation" focusable="false"><use xlink:href="${path}#${icon}"></use></svg>`;
    }
    static formatCurrency(input) {
        const digitsRegex = /(\d{3})(?=\d)/g;
        if (input == null || !isFinite(input)) {
            throw new Error('input needs to be a number');
        }
        input = parseFloat(input);
        const strVal = Math.floor(Math.abs(input)).toString();
        const mod = strVal.length % 3;
        const h = mod > 0 ? (strVal.slice(0, mod) + (strVal.length > 3 ? ',' : '')) : '';
        const v = Math.abs(parseInt((input * 100) % 100, 10));
        const float = (v > 0) ? '.' + (v < 10 ? ('0' + v) : v) : '';
        return (input < 0 ? '-' : '') + h + strVal.slice(mod).replace(digitsRegex, '$1,') + float;
    }
    static formatOrdinal(input) {
        if (input == null || !isFinite(input)) {
            throw new Error('input needs to be a number');
        }
        input = parseInt(input);
        let ordinal = 'th';
        let tens = input % 10;
        let hundreds = input % 100;
        if (tens === 1 && hundreds != 11) { ordinal = 'st'; }
        else if (tens === 2 && hundreds != 12) { ordinal = 'nd'; }
        else if (tens === 3 && hundreds != 13) { ordinal = 'rd'; }
        return `${input.toLocaleString('en-GB', { useGrouping: true })}${ordinal}`;
    }
}

// Cookie handling helper functions
Helper.Cookie = class {
    static set(name, value, days) {
        const expiryDate = new Date(new Date().getTime() + (days * 24 * 60 * 60 * 1000));
        document.cookie = name + "=" + value + ";path=/;expires=" + expiryDate.toUTCString();
    }
    static get(name) {
        const cookie = document.cookie;
        const prefix = `${name}=`;
        let begin = cookie.indexOf(`; ${prefix}`);
        let end;
        if (begin == -1) {
            begin = cookie.indexOf(prefix);
            if (begin != 0) {
                return null;
            }
        }
        else {
            begin += 2;
            end = document.cookie.indexOf(';', begin);
            if (end == -1) {
                end = cookie.length;
            }
        }
        return decodeURI(cookie.substring(begin + prefix.length, end));
    }
    static destroy(name) {
        Helper.Cookie.Set(name, '', -1);
    }
};

// Focus state helper functions
Helper.Focus = class {
    static move($element, goInside = false) {
        if (goInside) {
            $element.find('a, object, :input, iframe, [tabindex]').eq(0).trigger('focus');
        }
        else {
            $element.attr('tabindex', '0').on('focus', () => {
                $element.css({ 'outline': '0' });
            }).on('blur', () => {
                $element.removeAttr('tabindex').css({ 'outline': '' });
            }).trigger('focus');
        }
    }
    static trap($element, namespace) {
        Helper.Focus.release($element, namespace); // remove any existing focus trap
        const $focusableElements = $element.find('a, object, :input, iframe, [tabindex]');
        const firstFocusable = $focusableElements.first().get(0);
        const lastFocusable = $focusableElements.last().get(0);
        $element.on(`keydown.${namespace}`, (e) => {
            if (e.which !== 9) { return; }
            if (e.shiftKey) {
                if (document.activeElement === firstFocusable) {
                    lastFocusable.focus();
                    e.preventDefault();
                }
            }
            else {
                if (document.activeElement === lastFocusable) {
                    firstFocusable.focus();
                    e.preventDefault();
                }
            }
        });
    }
    static release($element, namespace) {
        $element.off(`keydown.${namespace}`);
    }
};

Helper.Scroll = class {
    constructor($exception) {
        this.$exception = $exception;
        window.neverDoThisEver = {};
        window.neverDoThisEver.$exception = $exception; // This is terrible practice, but for the life of me I couldn't find out how to make this variable accessible within the `freeze` method.
        this.functionToBindTo = function(){};
        $(document).on('touchmove', () => { console.log('detecting touchmove'); });
    }
    freeze(e) {
        if(!window.neverDoThisEver.$exception.get(0).contains(e.target)) {
            e.preventDefault();
        }
        else {
        }
    }
    disable() {
        $('html').css({ 'overflow': 'hidden' });
        // $(document).on('touchmove', e => { this.freeze(e, this.$exception); });
        document.addEventListener('touchmove',  this.freeze, { passive: false });
        this.$exception.on('touchmove.navigation', (e) => {
            let top = this.$exception.scrollTop();
            let totalScroll = this.$exception.prop('scrollHeight');
            let currentScroll = top + this.$exception.prop('offsetHeight');
            console.log(top, totalScroll, currentScroll);
            if(top === 0 && currentScroll === totalScroll) {
                e.preventDefault();
            }
            else if(top === 0) {
                this.$exception.scrollTop(1);
            }
            else if(currentScroll === totalScroll) {
                this.$exception.scrollTop(top - 1);
            }
        });

    }
    enable() {
        $('html').css({ 'overflow': '' });
        document.removeEventListener('touchmove', this.freeze, { passive: false });
        // $(document).off('touchmove', e => { this.freeze(e, this.$exception); });
    }
};