class Event {
    constructor($container) {
        this.$container = $container;
        this.descriptionExpando();
    }
    descriptionExpando() {
        const $description = this.$container.find('.t-event__description');
        // Only do any of this if the description is long enough to warrant it 
        if($description.height() <= 300) { return; }
        // Collapse text
        $description.addClass('is-collapsed');
        // Build a button workshop
        const $button = $('<button/>', {
            type: 'button',
            class: 'a-button a-button--block'
        }).html(`${Helper.icon('plus', 'a-button__icon')} Show more`);
        $button.on('click', () => {
            if($description.hasClass('is-collapsed')) {
                // open it 
                $description.removeClass('is-collapsed');
                $button.html(`${Helper.icon('close', 'a-button__icon')} Show less`);
            }
            else {
                // close it 
                $description.addClass('is-collapsed');
                $button.html(`${Helper.icon('plus', 'a-button__icon')} Show more`);
            }
        });
        $description.append($button);
    }
}