class PasswordInput {
    constructor($field) {
        this.$field = $field;
        this.settings = {
            labelVisible: `${Helper.icon('eye-slash', 'm-password-input__icon')} <span class="u-screenreader">Hide password</span>`,
            labelHidden: `${Helper.icon('eye', 'm-password-input__icon')} <span class="u-screenreader">Reveal password</span>`
        };
        this.buildHtml();
        this.bindControls();
    }
    buildHtml() {
        this.$field.addClass('m-password-input__input').data('visible', false);
        this.$wrapper = $('<div/>', {
            'class': 'm-password-input'
        });
        this.$button = $('<button/>', {
            'type': 'button',
            'class': 'm-password-input__reveal',
            'html': this.settings.labelHidden
        });
        this.$field.wrap(this.$wrapper).after(this.$button);
    }
    bindControls() {
        this.$button.on('click', () => {
            if(this.$field.data('visible') === true) {
                this.$field.attr('type', 'password').data('visible', false);
                this.$button.html(this.settings.labelHidden);
            }
            else {
                this.$field.attr('type', 'text').data('visible', true); 
                this.$button.html(this.settings.labelVisible);
            }
        });
    }
}

$(document).ready(() => {
    $('[type="password"]').each((i, field) => {
        new PasswordInput($(field));
    });
});