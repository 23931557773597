class EventBooking {
    constructor($container) {
        this.$container = $container;
        this.initVueForm(this.$container);
    }
    initVueForm($container) {
        //const antiForgeryToken = $container.closest('form').find('[name="__RequestVerificationToken"]').val();
        const EventBookingForm = new Vue({
            el: $container.get(0),
            mounted() {
                this.initialized = true;
            },
            data: {
                initialized: false
            },
            watch: {
                
            },
            methods: {
                
            }
        });
    }
}

$(document).ready(() => {
    // $('.m-address-form').each((i, container) => {
    //     new AddressForm($(container));
    // });
})