     $(".o-sticky-nav").headroom({
         // vertical offset in px before element is first unpinned
         offset: 1000,
         // scroll tolerance in px before state changes
         tolerance: 0,
         // or you can specify tolerance individually for up/down scroll
         tolerance: {
             up: 0,
             down: 0
         },
         classes: {
             // when scrolling up
             pinned: "",
             // when scrolling down
             unpinned: "",
             initial: "headroom--unpinned",
             // when above offset
             top: "headroom--hide",
             // when below offset
             notTop: "headroom--show",
             // when not at bottom of scroll area
             notBottom: ""
         }
     });