class Tabs {
    constructor($container) {
        this.$container = $container;
        this.firstTabId = false;
        this.buildControls();
        this.openTab(this.firstTabId);
        // And add a class so we can hook some styles in
        this.$container.addClass('is-enhanced');
    }
    buildControls() {
        const tabArray = [];
        const $tabs = this.$container.find('.o-tabs__tab');
        if($tabs.length === 0) { return; }
        // Get all the child tabs 'n' loop thru 'em
        $tabs.each((i, tab) => {
            const $tab = $(tab);
            const id = `tab_${Helper.randomString()}`;
            const title = $tab.find('.o-tabs__title').text();
            tabArray.push({ id: id, title: title });
            $tab.attr({
                'hidden': 'hidden',
                'id': id,
                'aria-controlledby': `${id}_control`
            });
        });
        // Build the tab switching buttons
        this.firstTabId = tabArray[0].id;
        const $tabContainer = $('<div/>', {
            'class': 'o-tabs__navigation'
        });
        tabArray.forEach((item) => {
            const $button = $('<button/>', {
                'class': 'o-tabs__button',
                'type': 'button',
                'id': `${item.id}_control`,
                'aria-controls': item.id,
                'aria-expanded': 'false',
                'text': item.title
            }).on('click', () => {
                this.openTab(item.id);
            });
            $tabContainer.append($button);
        });
        this.$container.prepend($tabContainer);
    }
    openTab(id) {
        // Hide other tabs
        this.$container.find(`.o-tabs__tab:not(#${id})`).attr('hidden', 'hidden');
        // Mark all buttons as unexpanded
        this.$container.find(`.o-tabs__button:not(#${id}_control)`).attr('aria-expanded', 'false');
        // Show the tab
        this.$container.find(`.o-tabs__tab#${id}`).removeAttr('hidden');
        // Mark relevant button as selected
        this.$container.find(`.o-tabs__button#${id}_control`).attr('aria-expanded', 'true');
    }
}

$(document).ready(() => {
    $('.o-tabs').each((i, container) => {
        new Tabs($(container));
    });
});