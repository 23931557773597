class Toggle {
    constructor($switch, $target, condition, settings) {
        // Create default settings
        const defaultSettings = {
            switch: $switch,
            condition: condition,
            events: 'change',
            toggle: $target,
            truthyCallback: function() {},
            falsyCallback: function() {},
        };
        // Merge objects
        this.settings = $.extend(true, {}, defaultSettings, settings);
        // If either of the required things are missing, back out now
        if(!this.settings.switch || !this.settings.condition) { return; }
        // Initial stuff
        this.$switch = this.settings.switch;
        this.condition = this.settings.condition;
        this.$target = false; // will only be set if needed
        // Set up some things
        this.setupA11y();
        this.setupDeselectEvent();
        this.bindControls();
        // Run when code and DOM is loaded
        $(() => {
            this.checkState();
        });
    }
    setupA11y() {
        if(this.settings.toggle) {
            this.$target = this.settings.toggle;
            // Get the ID(s), or set new one(s) if necessary
            this.$target.each((i, elem) => {
                const targetId = $(elem).attr('id') || `toggle-${Helper.randomString()}`;
                $(elem).attr({
                    'id': targetId,
                    'aria-controlledby': this.getAllIDs(this.$switch)
                });
            });
            this.$switch.attr('aria-controls', this.getAllIDs(this.$target));
        }
    }
    setupDeselectEvent() {
        // This allows us to track when radio buttons become deselected
        // by assigning change events to other radios in the same group
        let selected = {};
        // Don't run this if the switch isn't a radio button
        if (this.$switch.is(':not([type="radio"])')) { return; }
        // Don't run this if the deselect event is already bound
        if (this.$switch.data('deselectEventBound')) { return; }
        // Get the name of the radio button group and add the
        // deselect event to all radios in the group
        $(`[name="${this.$switch.attr('name')}"]`).on('change.deselect', function() {
            if(this.name in selected && this != selected[this.name]) {
                $(selected[this.name]).trigger('deselect');
            }
            selected[this.name] = this;
        }).data('deselectEventBound', true).filter(':checked').each(function() {
            selected[this.name] = this;
        });
    }
    bindControls() {
        this.$switch.on(this.settings.events, () => {
            this.checkState();
        });
    }
    checkState() {
        if(this.condition()) {
            if(this.$target) { 
                this.$switch.attr('aria-expanded', 'true');
                this.$target.removeAttr('hidden');
            }
            this.settings.truthyCallback(this);
        }
        else {
            if(this.$target) { 
                this.$switch.attr('aria-expanded', 'false');
                this.$target.attr('hidden', 'hidden');
            }
            this.settings.falsyCallback(this);
        }
    }
    getAllIDs($selector) {
        let resultString = [];
        $selector.each((i, elem) => {
            const id = $(elem).attr('id') || `toggle-${Helper.randomString()}`;
            $(elem).attr('id', id);
            resultString.push(id);
        });
        return resultString.join(' ');
    }
}