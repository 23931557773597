class SearchForm {
    constructor($form) {
        this.$form = $form;
        if(this.$form.hasClass('m-search-form--collapsed')) {
            this.$form.data('collapsible', true);
            this.$form.data('collapsed', true);
        }
        this.bindControls();
    }
    bindControls() {
        this.$form.on('submit mouseover', (e) => {
            if(this.$form.data('collapsible') && this.$form.data('collapsed')) {
                // form is collapsed
                e.preventDefault();
                this.$form.data('collapsed', false).addClass('is-open');
                this.$form.find(':input:eq(0)').trigger('focus');
                return;
            }
            // otherwise, it's open
            // so just submit the form
        });
    }
}

$(document).ready(() => {
    $('.m-search-form').each((i, form) => {
        new SearchForm($(form));
    });
});