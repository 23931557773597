class GMap {
    constructor($map) {
        this.$map = $map;
        if(typeof Helper.config().GOOGLE_MAP_KEY === 'undefined') {
            // There's no API key configured, so this isn't gonna work
            console.warn('The Google Maps API key is missing or is configured incorrectly.');
            return; 
        }
        this.apiKey = Helper.config().GOOGLE_MAP_KEY;
        this.loadApi();
    }
    loadApi() {
        $.when(
            $.ajax({
                url: `https://maps.googleapis.com/maps/api/js?key=${this.apiKey}`,
                dataType: 'script',
                method: 'GET'
            })
        ).then(() => {
            this.initMap();
        });
    }
    initMap() {
        const mapConfig = this.explodeValues(this.$map.attr('data-centre'));
        this.map = new google.maps.Map(this.$map.get(0), {
            center: {
                lat: Number(mapConfig[0]) || 0,
                lng: Number(mapConfig[1]) || 0
            },
            zoom: Number(mapConfig[2]) || 13,
            disableDefaultUI: true,
            styles: [
                {
                    "featureType": "landscape.man_made",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#f7f1df"
                        }
                    ]
                },
                {
                    "featureType": "landscape.natural",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#d0e3b4"
                        }
                    ]
                },
                {
                    "featureType": "landscape.natural.terrain",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "labels",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "poi.business",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "poi.medical",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#fbd3da"
                        }
                    ]
                },
                {
                    "featureType": "poi.park",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#bde6ab"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "geometry.stroke",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "labels",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#ffe15f"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry.stroke",
                    "stylers": [
                        {
                            "color": "#efd151"
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#ffffff"
                        }
                    ]
                },
                {
                    "featureType": "road.local",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "black"
                        }
                    ]
                },
                {
                    "featureType": "transit.station.airport",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#cfb2db"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#a2daf2"
                        }
                    ]
                }
            ]
        });
        google.maps.event.addDomListener(window, 'resize', () => {
            this.map.setCenter({
                lat: Number(mapConfig[0]) || 0,
                lng: Number(mapConfig[1]) || 0
            });
        });
        this.initMarker();
    }
    initMarker() {
        if(!this.$map.attr('data-marker')) { return; }
        const markerConfig = this.explodeValues(this.$map.attr('data-marker'));
        new google.maps.Marker({
            map: this.map,
            position: {
                lat: Number(markerConfig[0]) || 0,
                lng: Number(markerConfig[1]) || 0
            }
        });
    }
    explodeValues(string) {
        return string.split(',').map((item) => item.trim());
    }
}

$(document).ready(() => {
    $('.a-map').each((i, map) => {
        new GMap($(map));
    });
});