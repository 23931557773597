new Toggle(
    $('#dietaryRequirements'),
    $('#otherDietaryRequirements'),
    function() {
        return this.$switch.val() == "Does not eat";
    }, {
        events: 'change',
        truthyCallback: function() {
            $('#otherDietaryRequirementsInput').attr('required', 'required');
        },
        falsyCallback: function() {
            $('#otherDietaryRequirementsInput').removeAttr('required');
        }
    }
);
new Toggle(
    $('#dietaryRequirements'),
    $('#allergyRequirements'),
    function() {
        return this.$switch.val() == "Allergies";
    }, {

        events: 'change',
        truthyCallback: function() {
            $('#allergyDetails').attr('required', 'required');
        },
        falsyCallback: function() {
            $('#allergyDetails').removeAttr('required');
        }
    }
);