class Collapsible {
    constructor($element) {
        this.$element = $element;
        this.$summary = this.$element.find('summary');
        // Accessibility
        const id = `collapsible-${Helper.randomString()}`;
        const $contentWrapper = $('<div/>', {
            'class': 'a-collapsible__body'
        });
        this.$element.children().not('summary').wrapAll($contentWrapper);
        this.$content = this.$element.children().not('summary');
        this.$summary.attr({
            'id': `${id}-summary`,
            'aria-controls': id
        });
        this.$content.attr({
            'id': id,
            'aria-controlledby': `${id}-summary`
        });
        // Initial state
        this.bindControls();
        if(!this.$element.attr('open')) {
            this.close();
        }
    }
    bindControls() {
        this.$summary.on('click', (e) => {
            e.preventDefault();
            this.toggle();
        });
    }
    toggle() {
        if(this.$element.data('collapsed') === true) {
            this.open();
            return;
        }
        this.close();
    }
    close() {
        this.$element.data('collapsed', true).removeAttr('open');
        this.$content.attr({
            'hidden': 'hidden',
            'aria-expanded': 'false'
        });
    }
    open() {
        this.$element.data('collapsed', false).attr('open', 'open');
        this.$content.removeAttr('hidden').attr({
            'aria-expanded': 'true'
        });
    }
}

$(document).ready(() => {
    if(!Modernizr.details) {
        $('.a-collapsible').each((i, element) => {
            new Collapsible($(element));
        });
    }
});