class ProductForm {
    constructor($form) {
        this.$form = $form;
        switch (this.$form.attr('id')) {
            case 'ProductPage':
                this.vueProductPage(this.$form);
                break;
            case 'FellowProductPage':
                this.vueFellowProductPage(this.$form);
                break;
            case 'AssociateProductPage':
                this.vueAssociateProductPage(this.$form);
                break;
            case 'StudentProductPage':
                this.vueStudentProductPage(this.$form);
                break;
            default:
                console.log('Error: Form ID not recognised.');
                break;
        }
    }
    vueProductPage($form) {
        const ProductForm = new Vue({
            el: `#${this.$form.attr('id')}`,
            data: {
                loadedPrices: false,
                price: 0,
                unit: false,
                error: false
            },
            computed: {
                readyToPurchase: function () {
                    if (this.loadedPrices) { return true; }
                    return false;
                }
            },
            methods: {
                loadPrices: function () {
                    const formData = $form.serialize();
                    $.ajax({
                        url: '/umbraco/Surface/Product/GetPrice',
                        method: 'post',
                        data: formData,
                        cache: false
                    }).done((data) => {
                        this.loadedPrices = true;
                        this.price = Helper.formatCurrency(data[0].price);
                        this.unit = data[0].unit;
                        this.error = false;
                    }).fail((jqXHR, textStatus, errorThrown) => {
                        console.log("Price AJAX error", jqXHR, textStatus, errorThrown);
                        this.loadedPrices = false;
                        this.error = jqXHR.responseJSON.errorText || errorThrown;
                    });
                }
            },
            mounted() {
                this.loadPrices();
            }
        });
    }
    vueFellowProductPage($form) {
        const ProductForm = new Vue({
            el: `#${this.$form.attr('id')}`,
            mounted() {
                this.initialized = true;
            },
            data: {
                initialized: false,
                loadedPrices: false,
                error: false,
                qualification: '',
                level: '',
                paymentMethod: '',
                paymentPeriod: '',
                prices: []
            },
            computed: {
                readyToPurchase: function () {
                    if (this.loadedPrices && this.qualification && this.paymentMethod && this.paymentPeriod) { return true; }
                    return false;
                }
            },
            watch: {
                qualification: function () {
                    this.level = '';
                    this.paymentMethod = '';
                    this.paymentPeriod = '';
                },
                level: function () {
                    this.paymentMethod = '';
                    this.paymentPeriod = '';
                },
                paymentMethod: function () {
                    this.paymentPeriod = '';
                    this.loadPrices();
                }
            },
            methods: {
                loadPrices: function () {
                    if (!this.qualification || !this.paymentMethod) { return; }
                    this.loadedPrices = false;
                    const formData = $(`#${$form.attr('id')}`).serialize();
                    $.ajax({
                        url: '/umbraco/Surface/Product/GetPrice',
                        method: 'post',
                        data: formData,
                        cache: false
                    }).done((data) => {
                        $.each(data, (i, priceOption) => {
                            console.log(priceOption);
                            priceOption.price = Helper.formatCurrency(priceOption.price);
                            priceOption.unitPrice = Helper.formatCurrency(priceOption.unitPrice);
                        });
                        this.prices = data;
                        this.loadedPrices = true;
                        this.error = false;
                    }).fail((jqXHR, textStatus, errorThrown) => {
                        console.log("Price AJAX error", jqXHR, textStatus, errorThrown);
                        this.error = jqXHR.responseJSON.errorText || errorThrown;
                    });
                }
            }
        });
    }
    vueAssociateProductPage($form) {
        const ProductForm = new Vue({
            el: `#${this.$form.attr('id')}`,
            mounted() {
                this.initialized = true;
            },
            data: {
                initialized: false,
                loadedPrices: false,
                error: false,
                qualification: '',
                paymentMethod: '',
                paymentPeriod: '',
                prices: []
            },
            computed: {
                readyToPurchase: function () {
                    if (this.loadedPrices && this.qualification && this.paymentMethod && this.paymentPeriod) { return true; }
                    return false;
                }
            },
            watch: {
                qualification: function () {
                    this.paymentMethod = '';
                    this.paymentPeriod = '';
                },
                paymentMethod: function () {
                    this.paymentPeriod = '';
                    this.loadPrices();
                }
            },
            methods: {
                loadPrices: function () {
                    if (!this.qualification || !this.paymentMethod) { return; }
                    this.loadedPrices = false;
                    const formData = $(`#${$form.attr('id')}`).serialize();
                    $.ajax({
                        url: '/umbraco/Surface/Product/GetPrice',
                        method: 'post',
                        data: formData,
                        cache: false
                    }).done((data) => {
                        $.each(data, (i, priceOption) => {
                            console.log(priceOption);
                            priceOption.price = Helper.formatCurrency(priceOption.price);
                            priceOption.unitPrice = Helper.formatCurrency(priceOption.unitPrice);
                        });
                        this.prices = data;
                        this.loadedPrices = true;
                        this.error = false;
                    }).fail((jqXHR, textStatus, errorThrown) => {
                        console.log("Price AJAX error", jqXHR, textStatus, errorThrown);
                        this.error = jqXHR.responseJSON.errorText || errorThrown;
                    });
                }
            }
        });
    }
    vueStudentProductPage($form) {
        const ProductForm = new Vue({
            el: `#${this.$form.attr('id')}`,
            mounted() {
                this.initialized = true;
            },
            data: {
                initialized: false,
                loadedPrices: false,
                error: false,
                qualification: '',
                paymentMethod: '',
                paymentPeriod: '',
                unitOfMeasureId: '',
                prices: []
            },
            computed: {
                readyToPurchase: function () {
                    if (this.loadedPrices && this.qualification && this.paymentMethod && this.paymentPeriod) { return true; }
                    return false;
                },
                showDirectDebitOption: function () {
                    if (this.qualification === '247960002') { return false; } // A-level option value
                    return true;
                }
            },
            watch: {
                qualification: function () {
                    this.paymentMethod = '';
                    this.paymentPeriod = '';
                    this.unitOfMeasureId = '';
                },
                paymentMethod: function () {
                    this.paymentPeriod = '';
                    this.unitOfMeasureId = '';
                    this.loadPrices();
                }
            },
            methods: {
                loadPrices: function () {
                    if (!this.qualification || !this.paymentMethod) { return; }
                    this.loadedPrices = false;
                    const formData = $(`#${$form.attr('id')}`).serialize();
                    $.ajax({
                        url: '/umbraco/Surface/Product/GetPrice',
                        method: 'post',
                        data: formData,
                        cache: false
                    }).done((data) => {
                        $.each(data, (i, priceOption) => {
                            console.log(priceOption);
                            priceOption.price = Helper.formatCurrency(priceOption.price);
                            priceOption.unitPrice = Helper.formatCurrency(priceOption.unitPrice);
                        });
                        this.prices = data;
                        this.loadedPrices = true;
                        this.error = false;
                    }).fail((jqXHR, textStatus, errorThrown) => {
                        console.log("Price AJAX error", jqXHR, textStatus, errorThrown);
                        this.error = jqXHR.responseJSON.errorText || errorThrown;
                    });
                }
            }
        });
    }
}