class AutocompleteSelect {
    constructor($module, options) {
    	const defaults = {
            width: '100%'
        };
        this.$module = $module;
        this.settings = $.extend(true, {}, defaults, options);
        // Init Select2 
        $module.select2(this.settings);
    }
}