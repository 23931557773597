class UI {
    constructor() {
        this.iePolyfills();
        this.lazyload();
        this.fitVids();
    }
    iePolyfills() {
        svg4everybody();
    }


    lazyload() {
        $('.a-media noscript').each((i, element) => {
            loadMedia(element, function() {
                $(this).parent('.a-media').addClass('a-media--loaded');
            }, true);
        });
    }
    fitVids() {
        $('iframe[src*="player.vimeo.com"], iframe[src*="youtube"], iframe[src*="youtube-nocookie.com"]').parent().fitVids();
    }
}


$(document).ready(() => {
    new UI();
});