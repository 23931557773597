class CookieConsent {
    constructor() {
        this.$container = $('.o-cookie-consent');
        if(this.$container.length < 1) { return; }
        this.$button = this.$container.find('.o-cookie-consent__agree');
        this.bindControls();
    }
    bindControls() {
        this.$button.on('click', () => {
            Helper.Cookie.set('acceptsCookies', 'true', 365);
            this.$container.slideUp(400, function() {
                $(this).remove();
            });
        });
    }
}

$(document).ready(() => {
    new CookieConsent();
});