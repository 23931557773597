class Table {
    constructor($table) {
        this.$table = $table;
        this.stealHeaders();
    }
    stealHeaders() {
        const columnHeaders = [];
        this.$table.find('thead > tr').children().each((i, cell) => {
            columnHeaders.push($(cell).text());
        });
        this.redistributeHeaders(columnHeaders);
    }
    redistributeHeaders(headerArray) {
        this.$table.find('tbody > tr').each((i, row) => {
            $(row).children().each((j, cell) => {
                $(cell).attr('data-th', headerArray[j]);
            });
        });
    }
}

$(document).ready(() => {
    $('table').each((i, table) => {
        new Table($(table));
    });
})