class AddressForm {
    constructor($container) {
        this.$container = $container;
        this.initVueForm(this.$container);
    }
    initVueForm($container) {
        const antiForgeryToken = $container.closest('form').find('[name="__RequestVerificationToken"]').val();
        const AddressForm = new Vue({
            el: $container.get(0),
            mounted() {
                this.initialized = true;
                if (this.selectedAddress.Line1 || this.selectedAddress.Line2 || this.selectedAddress.City || this.selectedAddress.Province) {
                    this.showFields = true;
                }
                console.log($container.find('[autocomplete="address-level1"]').eq(0).val(), $container.find('[autocomplete="address-level1"]').eq(1).val());
            },
            data: {
                initialized: false,
                error: false,
                showFields: $container.find('[data-error]') ? true : false,
                country: $container.find('[autocomplete="country"]').val() || 'GB',
                postcode: $container.find('[autocomplete="postal-code"]').val(),
                addressList: [],
                selectedAddress: {
                    Line1: $container.find('[autocomplete="address-line1"]').val(),
                    Line2: $container.find('[autocomplete="address-line2"]').val(),
                    City: $container.find('[autocomplete="address-level2"]').val(),
                    Province: $container.find('[autocomplete="country"]').val() === 'US' ? $container.find('[autocomplete="address-level1"]').eq(0).val() : $container.find('[autocomplete="address-level1"]').eq(1).val()
                },
                selectedAddressId: null
            },
            watch: {
                country: function () {
                    if (this.country === 'US') {
                        this.selectedAddress.Province = '';
                    }
                }
            },
            methods: {
                    enterKeyPress: function(e) {
                        if (e.keyCode === 13 || e.which === 13) {
                            e.preventDefault();
                            this.findAddresses();
                        }
                    },
                findAddresses: function () {
                    $.ajax({
                        url: '/umbraco/Surface/AddressFinder/FindByPostcode',
                        dataType: 'json',
                        method: 'POST',
                        cache: false,
                        data: {
                            postcode: this.postcode,
                            countryIso: this.country,
                            __RequestVerificationToken: antiForgeryToken
                        }
                    }).done((data) => {
                        console.log(data);
                        this.addressList = data.Addresses;
                        this.error = false;
                    }).fail((jqXHR, textStatus, errorThrown) => {
                        console.log("findAddresses AJAX error", jqXHR, textStatus, errorThrown);
                        this.addressList = [];
                        this.error = jqXHR.responseJSON.errorText || errorThrown;
                    }).always(() => {
                        this.selectedAddress = {};
                    });
                },
                getAddress: function () {
                    console.log('getAddress', this.selectedAddressId);
                    $.ajax({
                        url: '/umbraco/Surface/AddressFinder/GetAddressDetails',
                        dataType: 'json',
                        method: 'POST',
                        cache: false,
                        data: {
                            addressId: this.selectedAddressId,
                            __RequestVerificationToken: antiForgeryToken
                        }
                    }).done((data) => {
                        console.log(data);
                        this.selectedAddress = data;
                        this.showFields = true;
                        this.error = false;
                    }).fail((jqXHR, textStatus, errorThrown) => {
                        console.log("getAddress AJAX error", jqXHR, textStatus, errorThrown);
                        this.selectedAddress = {};
                        this.showFields = false;
                        this.error = jqXHR.responseJSON.errorText || errorThrown;
                    });
                }
            }
        });
    }
}

$(document).ready(() => {
    $('.m-address-form').each((i, container) => {
        new AddressForm($(container));
    });
})