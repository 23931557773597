class Modal {
    constructor($control, $modal) {
        this.$control = $control;
        this.$modal = $modal;
        this.$close = this.$modal.find('.o-modal__close');
        // Add some stuff
        this.$modal.addClass('is-enhanced').attr({
            'aria-modal': 'false', 
            'role': 'modal'
        });
        // Init the other stuff
        this.bindControls();
    }
    bindControls() {
        this.$control.on('click', () => {
            this.open();
        });
        this.$close.on('click', () => {
            this.close();
        });
    }
    open() {
        this.$modal.attr({
            'aria-modal': 'true',
            'open': 'open'
        });
    }
    close() {
        this.$modal.attr({
            'aria-modal': 'false'
        }).removeAttr('open');
    }
}