class CardForm {
    constructor($container, options) {
        // Settings
        const defaults = {
            numberInputSelector: '[name="CardNumber"]',
            providerInputSelector: '[name="CardType"]',
            expiryInputSelector: '[name="ExpiryDate"]',
            cscInputSelector: '[name="SecurityCode"]'
        };
        this.$container = $container;
        this.settings = $.extend(true, {}, defaults, options);
        this.cardRules = [
            { 
                displayName: 'Visa',
                iconName: 'Visa',
                sageProviderCodes: ['VISA', 'DELTA', 'UKE'],
                systemProviderCodes: ['1', '3', '5'],
                regex: /^4\d*$/,
                inputMask: '9{4} 9{4} 9{4} 9{4,7}',
                cscLength: 3
            },
            {
                displayName: 'MasterCard',
                iconName: 'Mastercard',
                sageProviderCodes: ['MC', 'MCDEBIT'],
                systemProviderCodes: ['2', '10'],
                regex: /^(5[1-5]|222[1-9]|22[3-9]|2[3-6]|27[0-1]|2720)\d*$/,
                inputMask: '9{4} 9{4} 9{4} 9{4}',
                cscLength: 3
            },
            { 
                displayName: 'American Express',
                iconName: 'Amex',
                sageProviderCodes: ['AMEX'],
                systemProviderCodes: ['6'],
                regex: /^3[47]\d*$/,
                inputMask: '9{4} 9{6} 9{5}',
                cscLength: 4
            },
            {
                displayName: 'Maestro',
                iconName: 'Mastercard',
                sageProviderCodes: ['MAESTRO'],
                systemProviderCodes: ['4'],
                regex: /^(5[06-9]|6[37])\d*$/,
                inputMask: '9{4} 9{4} 9{4} 9{0,7}',
                cscLength: 3
            },
            { 
                displayName: 'Discover',
                iconName: 'Discover',
                sageProviderCodes: ['DISCOVER'],
                systemProviderCodes: ['7'],
                regex: /^(6011|65|64[4-9])\d*$/,
                inputMask: '9{4} 9{4} 9{4} 9{4,7}',
                cscLength: 3
            },
            { 
                displayName: 'Diners Club',
                iconName: 'DinersClub',
                sageProviderCodes: ['DC'],
                systemProviderCodes: ['7'],
                regex: /^3(0[0-5]|[689])\d*$/,
                inputMask: '9{4} 9{6} 9{4,9}',
                cscLength: 3
            },
            { 
                displayName: 'JCB',
                iconName: 'JCB',
                sageProviderCodes: ['JCB'],
                systemProviderCodes: ['8'],
                regex: /^(2131|1800|35)\d*$/,
                inputMask: '9{4} 9{4} 9{4} 9{4,7}',
                cscLength: 3
            }
        ];
        // Initial state
        this.$container.find(this.settings.providerInputSelector).addClass('m-card-form__number');
        this.$container.find(this.settings.expiryInputSelector).inputmask('99/99');
        // Events
        this.bindControls();
    }
    bindControls() {
        this.$container.find(this.settings.providerInputSelector).on('change', (e) => {
            const $target = $(e.target);
            console.log(e.target);
            const selectedCardDetails = (() => {
                let foundCard;
                $.each(this.cardRules, (i, cardType) => {
                    if (cardType.systemProviderCodes.includes($target.val())) {
                        foundCard = cardType;
                        console.log('found card info', foundCard);
                        return false;
                    }
                });
                return foundCard;
            })();
            if(selectedCardDetails) {
                this.$container.find(this.settings.providerInputSelector).attr('data-card-provider', selectedCardDetails.iconName);
                this.$container.find(this.settings.numberInputSelector).inputmask(selectedCardDetails.inputMask);
                this.$container.find(this.settings.cscInputSelector).attr('maxlength', selectedCardDetails.cscLength);
            }
            else {
                this.$container.find(this.settings.providerInputSelector).removeAttr('data-card-provider');
                this.$container.find(this.settings.numberInputSelector).inputmask('remove');
                this.$container.find(this.settings.cscInputSelector).removeAttr('maxlength');
            }
        });
    }
}