function checkDateInput() {
    var input = document.createElement('input');
    input.setAttribute('type', 'date');

    var notADateValue = 'not-a-date';
    input.setAttribute('value', notADateValue);

    return (input.value !== notADateValue);
}

if (checkDateInput() === false) {

    $('input[type="date"]').pickadate({ format: 'dd/mm/yyyy' });
    $('input[type="time"]').pickatime({ interval: 15 });
}