class Navigation {
    constructor() {
        // Assign the elements
        this.$navigation = $('#navigation');
        this.$toggle = $('.a-menu-button');
        // Generate accessibility IDs
        this.navigationId = this.$navigation.attr('id') ? this.$navigation.attr('id') : `navigation-${Helper.randomString()}`;
        this.toggleId = this.$toggle.attr('id') ? this.$toggle.attr('id') : `navigation-${Helper.randomString()}`;
        // Add accessibility IDs
        this.$navigation.attr({
            'id': this.navigationId,
            'aria-controlledby': this.toggleId
        });
        this.$toggle.attr({
            'id': this.toggleId,
            'aria-controls': this.navigationId
        });
        this.$toggle.attr('aria-expanded', this.isVisible() ? 'true' : 'false');
        // Tell the navigation it's a good boy and can be whatever it wants when it grows up
        this.$navigation.addClass('is-enhanced');
        // Bind stuff
        // Scrolling control helper
        this.scrollHelper = new Helper.Scroll($('.o-navigation'));
        this.setupAccessibility();
        this.bindEvents();
        this.bindControls();
    }
    isMobile() {
        if (window.matchMedia('(min-width:760px)').matches) {
            // nope, this is not mobile layout
            return false;
        }
        // yes, this is mobile layout
        return true;
    }
    isVisible() {
        if (this.isMobile() && !$('html').hasClass('nav-open')) {
            return false;
        }
        return true;
    }
    setupAccessibility() {
        // Get all the primary navigation items
        this.$navigation.find('.o-navigation__list--primary > .o-navigation__item').each((i, item) => {
            const $item = $(item);
            // if they have a child div...
            if ($item.children('.o-navigation__dropdown').length > 0) {
                const id = `o-navigation-${Helper.randomString()}`;
                // ...get all the open buttons and put some stuff on them
                $item.children('.o-navigation__link--parent').attr({
                    'id': `${id}-open`,
                    'aria-controls': `${id}-dropdown`,
                }).on('click', (e) => {
                    e.preventDefault();
                    this.toggleSection($item);
                });
                // ...get the dropdown and put some stuff on it
                $item.children('.o-navigation__dropdown').attr({
                    'id': `${id}-dropdown`,
                    'aria-controlledby': `${id}-open`,
                    'hidden': 'hidden'
                });
                // ...get the items and put a hover state to open them
                
                $item.on('mouseover', () => {
                    if (this.isMobile()) { return; }
                    this.openSection($item);
                });
                // ...get the entire navigation and get a mouseout state to close it 
                this.$navigation.on('mouseout', () => {
                    if (this.isMobile()) { return; }
                    this.closeAllSections();
                });
            }
        });
    }
    toggleSection($item) {
        if ($item.data('o-navigation-open') === true) {
            this.closeSection($item);
            return;
        }
        this.openSection($item);
    }
    openSection($item) {
        if (!this.isMobile()) {
            this.closeAllSections();
        }
        const $dropdown = $item.children('.o-navigation__dropdown');
        $item.data('o-navigation-open', true).addClass('is-open');
        $dropdown.removeAttr('hidden');
    }
    closeSection($item) {
        const $dropdown = $item.children('.o-navigation__dropdown');
        $item.data('o-navigation-open', false).removeClass('is-open');
        $dropdown.attr('hidden', 'hidden');
    }
    closeAllSections() {
        this.$navigation.find('.o-navigation__list--primary > .o-navigation__item.is-open').each((i, item) => {
            this.closeSection($(item));
        });
    }
    openNavigation() {
        $('html').addClass('nav-open');
        this.$toggle.removeClass('is-closed').addClass('is-open').attr('aria-expanded', 'true');
        this.scrollHelper.disable();
    }
    closeNavigation() {
        $('html').removeClass('nav-open');
        this.$toggle.removeClass('is-open').addClass('is-closed').attr('aria-expanded', 'false');
        this.scrollHelper.enable();
    }
    bindEvents() {
        $(window).on('resize', () => {
            this.closeNavigation();
        });
    }
    bindControls() {
        this.$toggle.on('click', () => {
            if (this.isVisible()) {
                this.closeNavigation();
            } else {
                this.openNavigation();
            }
        });
    }
}

$(document).ready(() => {
    new Navigation();
});