/**
 * gets number of items in the cart for the current user.
 */
class CartCounter
{
    static init() {

        var $headerCart = $('.o-masthead__cart');
        if ($headerCart.length > 0)
        {
            var $tokenContainer = $('#global-token');
            var antiForgeryToken = $tokenContainer.find('[name="__RequestVerificationToken"]').val();
            $.post('/umbraco/Surface/CartCounter/GetCount',
                    {
                        __RequestVerificationToken: antiForgeryToken
                    })
                .always((result) => {
                    var $itemsCounter = $headerCart.find('.a-cart-button__items');
                    //check result is expeced number and not exception/error before binding to html
                    if ($.isNumeric(result)) {
                        $itemsCounter.text(result);
                    }

                });
        }

    }
}

CartCounter.init();