class SearchResults {
    constructor() { // This function will run automatically when you call `new SearchResults()`.
        this.$container = $('.t-search-results__filters'); // The containing element for the button AND filter list. We use this to scope stuff.
        this.$button = this.$container.find('.t-search-results__filters-title'); // The jQuery selector for the button
        this.$filterList = this.$container.find('.t-search-results__filters__container'); // The jQuery selector for the filter list
        this.isOpen = true;
        this.bindControls();
        this.determineDefault(); // run this immediately to determine state
    }

    bindControls() { // Adds event listeners for our own stuff
        this.$button.on('click', () => {
            this.toggle();
        });
    }
    toggle() { // switch between open and closed
        if (this.isOpen) {
            this.close();
        } else {
            this.open();
        }
    }
    open() { // force open
        this.isOpen = true;
        this.$filterList.show();
        this.$button.find('.t-search-results__filters__button').text('-');
    }
    close() { // force closed
        this.isOpen = false;
        this.$filterList.hide();
        this.$button.find('.t-search-results__filters__button').text('+');
    }
    determineDefault() {
        if (window.matchMedia('(max-width: 760px)').matches) {
            // if mobile, close it by default
            this.close();
        } else {
            // if desktop, open it by default
            this.open();
        }
    }


}
$(document).ready(() => {
    new SearchResults();
});